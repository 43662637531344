import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GiStarShuriken } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { FaAngleDoubleRight } from 'react-icons/fa';

const ourProduct = [
    {
        title: "Fx-Pro",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "CMC Markets",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "How to Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Wallstreet Coach",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Wallstreet 101",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Forex UPS",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "FINLATO",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Track a Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Mark Dickinson",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../Images/ourProductMobile.jpg')}`,
        url: ''
    },
]

const Service = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const text = () => {
        let arr = []
        for (let i = 0; i < 10; i++) {
            arr.push(
                <p class='d-flex gap-5 fs-1  text-nowrap  text-black'>
                    <span>Let's Invest Together</span>
                    <span><GiStarShuriken /></span>
                </p>

            )
        }
        return arr;
    }

    const navigate = useNavigate()
    return (
        <div>
            <div class='col-11 m-auto mt-lg-0 mt-5 row align-items-center serviceTop' >
                <div class='col-lg-7'>
                    <div class=' m-auto text-start mt-lg-0 mt-5'>
                        <p class='fwt display-4 '>Our <span class='org fwsb'>Stellar Work <br /> & Services</span></p>
                        <p class='display-4'>That Will Launch <br /><span class='fwb org'> Your Success!</span> </p>
                        <p class='fwt tJustify'>Experience a world-class trading environment curated just
                            for you. We’ve partnered with internationally recognized
                            brands and well-regulated institutions that offer state-of-the-art .</p>
                        <button class='sumBtn mfs-4 rounded-5 mt-3'>Explore More</button>


                    </div>
                </div>
                <div class='col-lg-5'>
                    <div class='col-lg-11 col-md-7 m-auto  my-lg-0 my-4 position-relative' data-aos="zoom-in">
                        <img src={require('../Images/servicetop1.png')} class='rotation3' style={{ width: '100%', transition: '2s' }} alt="" />
                        <img src={require('../Images/servicetop2.png')} class='rotation2' style={{ width: '100%', transition: '1.5s', position: 'absolute', top: '0', left: '0' }} alt="" />
                        <img src={require('../Images/servicetop3.png')} class='rotation1' style={{ width: '100%', transition: '1s', position: 'absolute', top: '0', left: '0' }} alt="" />
                        <img src={require('../Images/servicetop4.png')} class='rotation4' style={{ width: '100%', position: 'absolute', top: '0', left: '0', transform: "rotate(0deg)" }} alt="" />
                        {/* <img src={require('../Images/servicetop4_2.png')} class='rotation3' style={{width:'100%',position:'absolute', top:'0',left:'0',transform:"rotate(60deg)"}} alt="" />
                        <img src={require('../Images/servicetop4_3.png')} class='rotation3' style={{width:'100%',position:'absolute', top:'0',left:'0',transform:"rotate(30deg)"}} alt="" />
                        <img src={require('../Images/servicetop4_4.png')} class='rotation3' style={{width:'100%',position:'absolute', top:'0',left:'0',transform:"rotate(60deg)"}} alt="" /> */}

                        <img src={require('../Images/servicetop5.png')} style={{ width: '100%', position: 'absolute', top: '0', left: '0' }} alt="" />


                    </div>
                </div>


            </div>

            <div style={{ overflow: 'hidden', background: 'rgba(255,130,60,0.2)' }}>
                <div class='d-flex gap-4 my-3' style={{ transform: `translateX(-${scrollPosition}px)` }}>{text()}</div>
            </div>

            <div class='col-11 m-auto mt-5'>
                <p class='fs-1'> Things That we're <span class='fwsb org'>Expert on</span></p>

                {
                    ourProduct?.map((el, index) => {
                        return (
                            <div data-aos="fade-up" class='row gap-lg-0 gap-4 border border-black  align-items-center rounded-5 flex-column-reverse flex-lg-row p-0 m-0 mt-5' id={`investD${index}`} style={{ overflow: 'hidden', position: 'relative' }}>
                                <div class='col-xl-7 col-lg-6' style={{ zIndex: '1' }}>
                                    <div class='text-start col-11 m-auto my-3 text-black'>
                                        <p class='fs-1 fweb'>{el?.title}</p>
                                        <p class='fs-5 fwt my-2'>{el?.des}</p>
                                        <div class='row'>
                                            {el?.bPoint?.map((e) => {
                                                return (
                                                    <p class='fwt mt-1 col-md-6 d-flex gap-2'>
                                                        <span class='org'><FaAngleDoubleRight /></span>
                                                        {e}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                        <button class='sumBtn mfs-4 rounded-5 mt-3'>Explore More</button>


                                    </div>
                                </div>
                                <div class='col-xl-5 col-lg-6  p-0 d-flex justify-content-center align-items-end' style={{ overflow: 'hidden', zIndex: '1' }}>
                                    <div class=' col-12'>
                                        <img style={{ width: '100%' }} src={el?.img} class='invest2cartImg' alt="" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <br />
        </div>
    )
}

export default Service