import React, { useEffect, useState } from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css';
import LoginLanding from './LoginLanding';
import LoginForm from './LoginForm';
import LoginQuesAns from './LoginQuesAns';
import { useNavigate } from 'react-router-dom';

const LogIn = () => { 
  const [showScreen, setShowScreen] = useState('first')
  let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");
  let sum3UserRole = localStorage.getItem("sum3UserRole");

  const navigate = useNavigate()


  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [])  

  useEffect(()=>{
    if(sum3UserAccessToken){
      navigate(`/${sum3UserRole}-invest`)
    }
  },[sum3UserAccessToken])


  return (
    <div>
      {showScreen == "first" &&
         <LoginLanding screen={setShowScreen}/>
      }
      {showScreen == "second" &&
        <LoginForm screen={setShowScreen}/>
      }
      {showScreen == "third" &&
        <LoginQuesAns screen={setShowScreen}/>
      }
    </div>
  )
}

export default LogIn